import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take  } from 'rxjs/operators';
import * as io from 'socket.io-client';  // Correct import for version 2.3.0
import { Socket } from 'socket.io-client'; // Import the type definition

// import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { environment } from "../../environments/environment";
import { IsLoggedService } from './data-share/is-logged.service';
import { AuthService } from './auth/auth.service';
let baseUrl = `${environment.HOST}`;

export interface UserDataGeoLocation {
  student_id: string;
  latitude: number;
  longitude: number;
  dateTime: number;
  type:string;
  socket_id: string;
  // fileName?: string;
  // video_id?: number;
}
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket;
  private sessionIdKey = 'sessionId';
  private sessionId: string;
  student_id: string;
  socket_id: string
  options = {
    enableHighAccuracy: true, // Provides a hint to try to get the best possible results
    timeout: 5000, // The maximum length of time (in milliseconds) the device is allowed to take in order to return a position
    maximumAge: 0 // Indicates that the application wants to receive the most recent location
  };
  geoLocationData: {latitude: number, longitude: number}
    type:string
    constructor( private isLoggedService: IsLoggedService,  private authService: AuthService) { 
    console.log('SocketService was created!');
    this.sessionId = this.getSessionId(); // Get or create a persistent session ID
    this.socket = io(`${baseUrl}`, {
      withCredentials: true,
      transports: ['polling', 'websocket'],
      query: { sessionId: this.sessionId } // Include the session ID here
    });
    
    this.connect()
    this.handleDisconnection();
    this.socket.on('your_id',(socket_id: any)=>{
      console.log('your_id:',socket_id)
      localStorage.setItem(`socketId`, socket_id)
    })
    this.listenForGeoLocationRequest();
    this.listenToLogOutUserRequest();
    // Handle the page unload or close event
    window.addEventListener('beforeunload', () => {
      this.handlePageUnload();
    });
    this.getStudentId()
  }


  getStudentId(){
    this.socket.on('getStudent_id',()=>{
    this.isLoggedService.getData().subscribe((data)=>{
      console.log('getStudentId - data:', data);
      
      if(data){
        if(data.user.studentID){
          console.log('this.socket.on( getStudent_id), student_id:===========>:',data.user.studentID);
          this.student_id = data.user.studentID
          this.socket.emit('postStudentId',{student_id: this.student_id})
        }
      }else{
       // this.student_id = ''
        console.log('can not emit postStudentId - user have not logIn yet! ');
        
      }
    })
  })
  }
/***********************************************************************/

  // Get or create a persistent session ID
  getSessionId(): string {
    let sessionId = localStorage.getItem(this.sessionIdKey);
    if (!sessionId) {
      sessionId = this.generateSessionId();
      localStorage.setItem(this.sessionIdKey, sessionId);
    }
    return sessionId;
  }

  generateSessionId(): string {
    return Math.random().toString(36).substr(2) + Date.now().toString(36);
  }

  // Handle the page unload or close event
  handlePageUnload() {
    console.log('Page is being closed or refreshed.');
    this.socket.emit('client_disconnect', { sessionId: this.sessionId });
    this.socket.disconnect();
  }

/*********************************************************************/

    // Listen for 'getGeoLocation' event and respond with geo-location data
    listenForGeoLocationRequest() {
      console.log('before - getGeoLocation ');
      
      this.socket.on(`getGeoLocation`, (type: string) => {
        console.log('getGeoLocation event received',type);
        this.type = type
        // Fetch the geo-location data (this could be a real function to get the actual geo-location)
        this.emitGeoLocationData();
        //this.socket.emit('geoLocationData', geoData);
      });
    }
    // function to get geo-location data
    emitGeoLocationData() {
      this.getGeoLocation()
      // This is a mock function. Replace with actual logic to get geo-location.
    //  this.IsLoggedService.getGeoLocation()
      //return { latitude: 37.7749, longitude: -122.4194 }; // Example data
    }
    // Emit the geo-location data back to the backend
    sendMessage(geoData: any) {
      console.log('sendMessage=========>',geoData);
      this.socket.emit('geoLocationData', geoData);
    }
    
      // Listen for an event
  // onMessage(): Observable<string> {
  //   return this.socket.fromEvent<string>('getGeoLocation');
  // }

  getGeoLocation(){
    navigator.geolocation.getCurrentPosition(this.success, this.error,this.options);
  }
  success=(position: any)=>{
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    const location  = {latitude, longitude }
    
    const dateTime = Date.now()
    // const socket_id = localStorage.getItem("socketId");
    let  userGeoLocationData: UserDataGeoLocation
    //if(this.type !=='login'){
      this.isLoggedService.getData().pipe(
        filter(data => !!data && !!data.user), // Ensure data and user are not null or undefined
        take(1) // Take only the first valid emission
      ).subscribe(data => {
        console.log('SocketService - user.user:', data.user.studentID);
        this.student_id = data.user.studentID;
        const socket_id = localStorage.getItem("socketId");
          userGeoLocationData = {
                student_id :this.student_id,
                ...location,
                dateTime: dateTime, 
                type: this.type,
                socket_id: socket_id
        }
          this.sendMessage(userGeoLocationData)
      });
  }
  error=(err: any)=> {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  connect() {
    this.socket.on('connect', () => {
      this.socket_id = this.socket.id
      localStorage.setItem(`socketId`, this.socket_id)
      console.log('Connected to the socket service:', this.socket.id);
    });
  }

  handleDisconnection() {
    this.socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });
  }

  listenToLogOutUserRequest(){
    this.socket.on('logOutUser',(socketId)=>{
      console.log('logOutUser - socketId:',socketId);
      const socket_id = localStorage.getItem("socketId");
      if(socket_id === socketId ){
        this.isLoggedService.setUserLoggedIn(false);
        sessionStorage.clear();
        this.authService.logout();
      }
    })
  }

  onTeacherChoosedUnickoUser(unickoUser: string,portalUser: string){
    const socket_id = localStorage.getItem("socketId");
    const unickoUserData = {socket_id, unickoUser,portalUser}
    this.socket.emit('TeacherChoosedUnickoUser', unickoUserData)
  }
    // Method to listen for the event
    getTeacherChoosedUnickoUser(): Observable<{ unickoUser: string; portalUser: string }> {
      return new Observable((observer) => {
        this.socket.on('TeacherChoosedUnickoUser', (unickoUserData: {unickoUser: string,portalUser: string}) => {
          observer.next(unickoUserData);
        });
      });
    }


    activateTheGetActiveUnickoUsers(){
      const socket_id = localStorage.getItem("socketId");
      this.socket.emit('ActivateTheGetActiveUnickoUsers',socket_id)
    }
    getActiveUnickoUsers(): Observable<{ unickoUser: string; portalUser: string }[]> {
      return new Observable((observer) => {
        this.socket.on('activeUnickoUsers', (activeUnickoUsers :{ unickoUser: string; portalUser: string }[]) => {
          observer.next(activeUnickoUsers);
        });
      }); 
    }
    releaseUnickoUser(userId: string){
      console.log('releaseUnickoUser');
      
      this.socket.emit('releaseUnickoUser', userId)
    }


}
