import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

/* module imports. */
import { ModalService } from "app/modules/shared/components/modal/modal.service";
/* service imports. */
import { ServerApiService } from "app/services/server-api.service";
import { TeacherDataService } from "app/services/teachers/teacher-data.service";

/* model imports. */
import { CoursesPerStudent } from "app/models/interfaces/course/coursePerStudent";
import { CycleStudents } from "app/models/interfaces/cycle/cycleStudent";
import { TeacherCalendar } from "app/models/interfaces/teacher/teacherCalendar";
import { TeacherCycles } from "app/models/interfaces/cycle/cycleTeacher";
import { CourseStatus } from "app/models/classes/courseStatus";

import * as _ from "underscore";
import { SocketService } from "app/services/socket.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { User } from "app/models/interfaces/user/user";
declare var $: any;
@Component({
  selector: "app-teacher-list",
  templateUrl: "./teacher-list.component.html",
  styleUrls: ["./teacher-list.component.css"],
  providers: [ServerApiService],
})
export class TeacherListComponent implements OnInit {
  @ViewChild('myDialog', { static: false }) myDialog!: ElementRef;
  courseStatus: CourseStatus = new CourseStatus();
  teacherCycles: TeacherCycles[] = [];
  calendar: TeacherCalendar[];
  cycleStudents: CycleStudents[] = [];
  coursesPerStudent: CoursesPerStudent[] = [];
  isCycleStudents: boolean = false;
  isCommentModal : boolean = false;
  student_id = "";
  host_url: any;
  users: object;
  active_usr: string;
  activeUsers: boolean[] = []
  courseCycleID: string;
  courseMeetingId: number;
  toggler: boolean = true;
  courseToShow;
  displayStyle = "none";
  studentName : string  = ''
  studentFamilyName : string =''
  commentStudent : string = null
  isActive :boolean = false
  picker : any = 'abc'	
  startDate : String = null	
  endDate : String =  null	
  // usedUnickoUsers:string[]=[]
  usedUnickoUsers: { unickoUser: string; portalUser: string }[] = [];
  teacherPath :Object[] = [	
    {category: 'All'}	
]	
  isTestModal:boolean = false
  dayTime = ['All','Morning','Evening']	
  timeSelected = 'All'	
  choosedPath = {	
    category: 'All'	
  }
  thisUnickUserSelected:string = ''
  userTaken = {
    status: false,
    id: ''
  }
  logedInUser:User|null = null
  

  constructor(
    private ServerApiService: ServerApiService,
    private modalService: ModalService,
    private TeacherDataService: TeacherDataService,
    private socketService: SocketService,
    private IsLoggedService: IsLoggedService,
  ) {
    this.loadData();
    this.TeacherDataService.teacherloadend.subscribe((r) => {
      this.loadData();
	//console.log('hey',this.teacherCycles);
      if(this.teacherCycles && this.teacherCycles.length){

      for (let i = 0; i < this.teacherCycles.length; i++){
        if(!this.teacherCycles[i].opendate){
          this.teacherCycles.splice(i, 1);
          i--;
        }
      }
}
      console.log(this.teacherCycles);
    });

    this.activeUsers = [false,false,false,false]
    this.users = {
      acc_t: "306970",
      acc_b: "1555192",
      acc_s: "1558045",
      oleg: "1565179",
     // alex: "1967797",
    };

  }

  ngOnInit() {
 //   console.log(this.cycleStudents);
 //   console.log('ngOnInit on teacher-list',this.cycleStudents);	
    this.TeacherDataService.getPath().subscribe((path)=>{	
    this.teacherPath = path	
  //   console.log('this.teacherPath====.then=============>',path)	
    })	
    const endDate =  new Date(new Date().setFullYear(new Date().getFullYear() + 1))	
    const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))	
    this.startDate = this.formatDate(startDate);	
    this.endDate =  this.formatDate(endDate);
    // when some user choose a unicko-user, he send it to all other users.
    // so this function gets it.
    this.socketService.getTeacherChoosedUnickoUser().subscribe((unickoUserData: {unickoUser: string,portalUser: string}) => {
      console.log('getTeacherChoosedUnickoUser:', unickoUserData);
      this.usedUnickoUsers.push(unickoUserData)
      console.log('getTeacherChoosedUnickoUser -  this.usedUnickoUsers',  this.usedUnickoUsers);
    });

    //activate the "getActiveUnickoUsers" from the server side
    this.socketService.activateTheGetActiveUnickoUsers()
    this.socketService.getActiveUnickoUsers().subscribe((activeUnickoUsers :{ unickoUser: string; portalUser: string }[]) => { //{ unickoUser: string; portalUser: string }[]
      console.log('activeUnickoUsers',activeUnickoUsers);
      this.usedUnickoUsers = activeUnickoUsers
      // this.userTaken.status =  this.usedUnickoUsers.includes(this.userTaken.id) ? true : false
      this.userTaken.status = this.usedUnickoUsers.some( item => item.unickoUser === this.userTaken.id );
      this.userTaken.id = this.userTaken.status ? this.userTaken.id :null
    })
    //  this.isTestModal = true
    this.IsLoggedService.getData().subscribe((data: User) => {
      this.logedInUser = data;
      console.log('teschers user logedIn==============> : ', this.logedInUser.user.firstName);
    })

    
  }

  // closeUnickoDialog(){
  //   this.myDialog.nativeElement.close();
  //   console.log('this.thisUnickUserSelected:',this.thisUnickUserSelected);
    
  // }
  /**
   * Function to hide the unicko button if the course already end.
   * @param theStatus A string variable that contains the course updating status for database.
   * @returns Boolean value that represent if the course already completed, if yes then the unicko open button will not show up. Otherwise will show.
   */
  isTheCourseEnd(theStatus: string): boolean {
    return this.courseStatus
      .getCourseStatusArray()
      .includes(theStatus.toLocaleLowerCase());
  }

  loadData() {
    this.teacherCycles = this.TeacherDataService.getTeacherCourse();
    this.calendar = this.TeacherDataService.getTeacherCalendar();
  }

  openCourse: string = "";
  toggle(course) {
    if(course.courseName != this.openCourse){
      this.openCourse = course.courseName
    }else{
      this.openCourse = ""
    }
    console.log("course", course,"openCourse", this.openCourse)
    // this.courseToShow = course;
    // this.toggler = !this.toggler;
  }

  openHostAccModal(courseMeetingId, courseID) {
    console.log('document.body.offsetWidth :',document.body.offsetWidth );
    console.log(window.innerWidth + 'px');
    this.courseMeetingId = courseMeetingId;
    this.courseCycleID = courseID;
    let modalWidth = Math.floor(document.body.offsetWidth*0.5 );
    let x: number = Math.floor(document.body.offsetWidth * 0.3);
    let y: number = Math.floor(document.body.offsetHeight * 0.1);
    this.openModal("custom-modal-5", x, y, modalWidth);
  }

  openHostLink(meeting: number, host_acc: any, cycle) {
    console.log('openHostLink:',meeting,host_acc,cycle);
    // this.myDialog.nativeElement.showModal();
    this.userTaken.status =  this.usedUnickoUsers.includes(host_acc.value) ? true : false
    console.log('this.userTaken ============================>',this.userTaken,'this.usedUnickoUsers:',this.usedUnickoUsers  );
    
  //  if(!this.userTaken.status){
      this.TeacherDataService.giveMeHostLink(meeting, host_acc, cycle);
      this.closeModal("custom-modal-5");
      this.isActive = true
      this.socketService.onTeacherChoosedUnickoUser(host_acc.value,this.logedInUser.user.firstName)
      this.thisUnickUserSelected = host_acc.value
   // }else{
      this.userTaken.id = host_acc.value
   // }
  
  }

  getCycleStudents(cycle) {
    this.ServerApiService.fetchCycleStudents(cycle).subscribe(
      (data: CycleStudents[]) => {
        console.log("TeacherListComponent - getCycleStudents - data", data);
        this.cycleStudents = data;
        let modalWidth = Math.floor(document.body.offsetWidth * 0.8);
        let x: number = Math.floor(document.body.offsetWidth * 0.1);
        let y: number = Math.floor(document.body.offsetHeight * 0.1);
        this.openModal("custom-modal-4", x, y, modalWidth);
      },
      (error) => {
        console.log("err!!", error);
      }
    );
  }

  // modal window
  openModal(id: string, x: number, y: number, l: number) {
    this.isCycleStudents = true;
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
    this.isCycleStudents = false;
  }

  openModalG(id: string, x: number, y: number, l: number) {
    this.modalService.open(id, x, y, l);
  }
  closeModalG(id: string) {
    this.modalService.close(id);
  }

  saveMarks(c, f) {
    this.coursesPerStudent = [];
    let stop = false;
    for (const key of Object.keys(f.value)) {
      let coursePerStudent: CoursesPerStudent = _.findWhere(
        this.coursesPerStudent,
        { student: key.substring(1) }
      );
      if (coursePerStudent === undefined) {
        coursePerStudent = {
          student: key.substring(1),
          course: c,
          examMark: 0,
          projectMark: 0,
          code: 0,
          passed: 0,
          timesRepeated: 0,
          showingUp: 0,
          freezCourse: 0,
          freezeDate: null,
          enable_replay: 0,
          comment : ''
        };
        if (f.value[key] > 100 || f.value[key] < 0) {
          let modalWidth = Math.floor(document.body.offsetWidth * 0.33);
          let x: number = Math.floor(document.body.offsetWidth * 0.4);
          let y: number = Math.floor(document.body.offsetHeight * 0.1);
          this.openModalG("invalid-grade-err", x, y, modalWidth);
          stop = true;
          break;
        }
        if (key[0] == "e") {
          coursePerStudent.examMark = f.value[key];
        } else if (key[0] == "p") {
          coursePerStudent.projectMark = f.value[key];
        }
        if(this.commentStudent){
          coursePerStudent.comment = this.commentStudent
          this.commentStudent = null
        }
        this.coursesPerStudent.push(coursePerStudent);
      } else {
        if (key[0] == "e") {
          coursePerStudent.examMark = f.value[key];
        } else if (key[0] == "p") {
          coursePerStudent.projectMark = f.value[key];
        }
      }
    }
    //console.log(this.coursesPerStudent);
    if (!stop) {
      let courseperstudent = {
        courseperstudent: this.coursesPerStudent,
      };
      let str = JSON.stringify(courseperstudent);
      this.ServerApiService.postCoursesPerStudent(str).subscribe(
        (data) => {
          this.showNotification("bottom", "left", 2, "Marks Saved");
          this.commentStudent = null
          this.closeModal("custom-modal-4");
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  showNotification(from, align, t: number, mess: string) {
    const type = ["", "info", "success", "warning", "danger"];
    $.notify(
      {
        icon: "notifications",
        message: mess,
      },
      {
        type: type[t],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }


  openCommentModal(studentName : string,studentFamilyName : string, comment: string){
    console.log('open comment modal',studentName)
    // let modalWidth = Math.floor(document.body.offsetWidth * 0.33);
    // let x: number = Math.floor(document.body.offsetWidth * 0.4);
    // let y: number = Math.floor(document.body.offsetHeight * 0.1);
    // this.openModal('custom-modal-6',x,y,modalWidth)
    //  this.ServerApiService.fetchCommentStudent(id).subscribe((comment)=>{
    //   console.log('the comment from db :', comment)
    //   this.commentStudent = comment
    //  })
      this.commentStudent = comment
    this.studentName = studentName
    this.studentFamilyName = studentFamilyName
    this.displayStyle = "block";
    this.isCommentModal = true
  }
  saveComment(){
    console.log('close comment modal', this.commentStudent)
    this.displayStyle = "none";
    this.isCommentModal = false
  //  this.commentStudent = null
  }
  cancelComment(){
    console.log('close comment modal')
    this.displayStyle = "none";
    this.isCommentModal = false
    this.commentStudent = null
  }

  	  /********************************** update **************************** */	
      choosePath() {	
        // console.log('prod_group =========>  : ', prod_group)	
        // const choosedCoursess = this.teacherPath.filter((course) => {	
        //   return course.category == this.choosedPath	
        // })	
        // this.choosedPath = choosedCoursess	
        if(this.choosedPath){	
          console.log(' choosedPath :', this.choosedPath)	
        }	
        //   console.log('choosedCoursess =========>  : ', choosedCoursess)	
      }	
      filterCourse(){_	
      console.log(' start date :', this.startDate)	
      console.log(' end date :', this.endDate)	
      console.log(' choosedPath :', this.choosedPath)	
      console.log(' timeSelected :', this.timeSelected)	
      this.teacherCycles = null	
      this.TeacherDataService.fetchTeacherCyclesByFilter(this.startDate,this.endDate, this.choosedPath.category,this.timeSelected).subscribe((ans)=>{	
        console.log('fetchTeacherCyclesByFilter===========>', ans)	
        this.TeacherDataService.verifyLinks(ans)	
        this.teacherCycles =  ans	
      })	
      // this.ServerApiService.fetchTeacherCycles(this.student_id).subscribe((ans)=>{	
      //   console.log(' ans==========> :', ans)	
      // })	
      }	
      formatDate(date) {	
        return [	
            date.getFullYear(),	
            this.padTo2Digits(date.getMonth() + 1),	
            this.padTo2Digits(date.getDate()),	
        ].join("-");	
    }	
      padTo2Digits(num) {	
        return num.toString().padStart(2, "0");	
    }
    isUnickoUserInUse(value: string): boolean {
      return this.usedUnickoUsers.some(item => item.unickoUser === value);
    }
    unickUserTOPortalUSer(unickoId: string): string | undefined{
      const foundItem = this.usedUnickoUsers.find(
        (item) => item.unickoUser === unickoId
      );
      return foundItem?.portalUser; // returns undefined if not found
    }
}
